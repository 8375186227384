import { Container, Row, footer, Col } from "react-bootstrap"

import navIcon1 from '../assets/img/nav-icon1.svg';
import githubIcon from '../assets/img/download.svg';


export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                <Col sm={12} className="text-end">
                    <a href="https://www.linkedin.com/in/max-braithwaite-a6710026b" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt=" " /></a>
                    <a href="https://github.com/maxbraith" target="_blank" rel="noopener noreferrer"><img src={githubIcon} alt=" " /></a>
                </Col>
                </Row>
            </Container>
        </footer>
    )
}