import logo from './logo.svg';
import './App.css';
import { NavBar } from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from './components/Banner';
import { Projects } from "./components/Projects";
import {ProjectDesc} from "./components/ProjectDesc";
import {Contact} from "./components/Contact";
import {Footer} from "./components/Footer"
import {BrowserRouter, Router, Route} from "react-router-dom"


function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}


export default App;