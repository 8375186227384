import {Col, Container, Row, Nav} from "react-bootstrap";
import {ProjectCard} from "./ProjectCard";
import projImg1 from '../assets/img/darrenTillPreGif.png'

export const Projects = () => {
    const projects = [
        {
            title: "UFC Predictive Model",
            description: "Predictive Model using Tensorflow and Webscraping",
            imgURL: projImg1,
            link: "https://github.com/maxbraith/UFCModel",
        }
    ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {projects.map((project, index) => (
                        <Col key={index} md={12} lg={12} className="d-flex justify-content-center">
                            <a 
                              href={project.link} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              style={{ textDecoration: 'none' }} // Optional: removes default link underline
                            >
                                <ProjectCard 
                                    title={project.title}
                                    description={project.description}
                                    imgURL={project.imgURL} 
                                />
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};