import{ useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Banner = () => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const displayText = "- Max Braithwaite";
    const [delta, setDelta] = useState(300 - Math.random() * 100)
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        },delta)

        return () => { clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let fullText = displayText;
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if(isDeleting){
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);
        } else if(isDeleting && updatedText === ""){
            setIsDeleting(false)
            setDelta(500)
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <h1><span className="static-text">Portfolio&nbsp;</span><span className="wrap">{text}</span></h1>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}